export function getSubstring(obj: string, maxLength: number | null = null, minLength: number | null = null): string {
  const objLength = obj.length;
  const effectiveMaxLength = maxLength ?? objLength; // Use nullish coalescing for clarity
  const effectiveMinLength = minLength ?? 0;

  // Ensure bounds are valid
  const clampedMaxLength = Math.min(Math.max(effectiveMaxLength, 0), objLength);
  const clampedMinLength = Math.min(Math.max(effectiveMinLength, 0), objLength);

  // If minLength exceeds maxLength, return empty string or adjust logic as needed
  if (clampedMinLength >= clampedMaxLength) {
    return '';
  }

  return obj.substring(clampedMinLength, clampedMaxLength);
}


export function truncateString(obj: string, maxLength: number = 20): string {
  const objLength = obj.length;
  if (objLength > maxLength && maxLength){
    obj = getSubstring(obj, maxLength) + '...';
  }
  return obj;
}

/**
 * Removes characters from the beginning of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatInner(str: string, listObjects: string | string[]): string {
  if (!Array.isArray(listObjects)) {
    listObjects = [listObjects];
  }
  // Ensure str is a string
  str = String(str);

  // Remove characters from the beginning while they are in listObjects
  while (str.length > 0 && listObjects.includes(str[0])) {
    str = str.slice(1);
  }
  return str;
}

/**
 * Removes characters from the end of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatOuter(str: string, listObjects: string | string[]): string {
  if (!Array.isArray(listObjects)) {
    listObjects = [listObjects];
  }
  // Ensure str is a string
  str = String(str);

  // Remove characters from the end while they are in listObjects
  while (str.length > 0 && listObjects.includes(str[str.length - 1])) {
    str = str.slice(0, -1);
  }
  return str;
}

/**
 * Removes characters from both the beginning and the end of the string
 * if they are found in the list of characters.
 *
 * @param str - The input string.
 * @param listObjects - A string or an array of characters to remove.
 * @returns The modified string.
 */
export function eatAll(str: string, listObjects: string | string[]): string {
  return eatOuter(eatInner(str, listObjects), listObjects);
}

export function eatEnd(obj: string, endings: string[] = ['/']): string {
  let result = obj;
  let modified = true;
  while (modified) {
    modified = false;
    for (const ending of endings) {
      if (result.endsWith(ending)) {
        result = result.slice(0, -ending.length);
        modified = true;
        break;
      }
    }
  }
  return result;
}
