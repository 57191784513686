import * as path from 'path';
import {eatOuter,eatInner} from './string_utils';
import {ensure_list} from './type_utils';
export function get_dirname(filePath: string | null): string {
  if (!filePath) return '';
  return path.dirname(filePath);
}

export function get_basename(filePath: string | null): string {
  if (!filePath) return '';
  return path.basename(filePath);
}

export function get_filename(file_path: string) {
  const ext = path.extname(file_path);
  return path.basename(file_path, ext);
}

export function get_extname(filePath: string | null): string {
  if (!filePath) return '';
  return path.extname(filePath);
}

export function get_splitext(filePath: string | null) {
  if (!filePath) return { filename: '', extname: '' };
  const ext = path.extname(filePath);
  // Get the basename without the extension
  const filename = path.basename(filePath, ext);
  return { filename, ext};
}

export function make_path(...paths: string[]): string {
  const pathArray = ensure_list(paths);
  let real_path = '';
  for (let i = 0; i < pathArray.length; i++) {
    
    let path = pathArray[i]
    if (i === 0) {
      real_path = path;
    } else {
      path = eatInner(path, ['/']);
      real_path = eatOuter(real_path, ['/']);
      real_path = `${real_path}/${path}`;
    }
  }
  return real_path || '';
}

export function sanitizeFilename(filename: string): string {
  return filename
    .toLowerCase()
    .replace(/\s+|-/g, '-') // Replace spaces and hyphens with single hyphen
    .replace(/_/g, '-') // Replace underscores with hyphens
    .replace(/[^a-z0-9-.]/g, '') // Remove all non-alphanumeric chars except hyphen and dot
    .replace(/-+/g, '-') // Collapse multiple hyphens into one
    .replace(/^-|-$/, ''); // Remove leading/trailing hyphens
}



export function make_sanitized_path(...paths: string[]): string {
  let real_path = '';
  for (let i = 0; i < paths.length; i++) {
    const sanitized = sanitizeFilename(eatInner(paths[i], ['/']));
    if (i === 0) {
      real_path = sanitized;
    } else if (sanitized) { // Only append if there's a non-empty segment
      real_path = `${real_path}/${sanitized}`;
    }
  }
  return real_path || '';
}

export function normalizeUrl(base: string, path: string | undefined): string {{
  if (!path) return base;
  // Remove trailing slash from base and leading slash from path, then join with a single slash
  const cleanBase = base.replace('/\\/+$/', '');
  const cleanPath = path.replace('/^\\//', '');
  return `${{cleanBase}}/${{cleanPath}}`.replace('/([^:])\\/+/g', '$1/');
}}