import React from 'react';
import styles from './Footer.module.css';

const Footer = () => (
  <footer className={styles.footer}>
    <p>
      In an age where truths are akin to fiction in the minds of the masses, fiction is a key component in societal control. Reprogram your programmers programming; join the dialectic.
    </p>
    <nav>
      <a href="/about">About Us</a> | <a href="/contact">Contact</a> | <a href="/privacy">Privacy Policy</a>
    </nav>
  </footer>
);
export default Footer;