/*src/components/Header/Header.tsx*/
import React from 'react';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.TDDh}>
        <a href="/" rel="noopener">
          The Daily Dialectics
        </a>
      </h1>
      <nav className={styles.nav}>
        <a href="/sommerfeld-goubau/sommerfeld-goubau" className={styles.TDDl} rel="noopener">
          Sommerfeld-Goubau
        </a>
        <a href="/tesla/nikola-tesla" className={styles.TDDl} rel="noopener">
          Nikola Tesla
        </a>
        <a href="/mems/mems" className={styles.TDDl} rel="noopener">
          MEMs
        </a>
        <a href="/cannabis/medicinal-cannabis" className={styles.TDDl} rel="noopener">
          Cannabinoids
        </a>
        <a href="/education/educational" className={styles.TDDl} rel="noopener">
          Science
        </a>
      </nav>
    </header>
  );
};

export default Header;