
export function get_result<T>(obj: any): T {

  let current = obj;
  while (current && typeof current === 'object' && 'result' in current) {
    current = current.result;
  }
  return current as T;
}

export async function fetchIt(
  url: string,
  body: string | object | null = null,
  blob: boolean | null = null,
  headers: Record<string, string> | null = null,
  method: string = 'GET'
): Promise<any> {
  // Ensure the method is uppercase
  const httpMethod = method.toUpperCase();
  
  const options: RequestInit = {
    method: httpMethod,
    headers: headers || (httpMethod === 'POST' ? { 'Content-Type': 'application/json' } : undefined),
    body: body && httpMethod !== 'GET' ? JSON.stringify(body) : undefined,
  };



  const response = await fetch(url, options);


  if (!response.ok) {
    const errorData = await response.text();
    throw new Error(errorData || 'Webhook request failed');
  }

  return blob ? response.blob() : response.json();
}



