// /var/www/thedailydialectics/src/functions/functions.tsx
import {Source} from '@interfaces';
import path from 'path';
import {get_filename,make_path,get_result,fetchIt} from 'shared-functions'

// Function to inject iframes dynamically
export function iframeIt(title: string, link: string) {
  return `<div class="iframe-container"><center>${title}<iframe width="560" height="315" src="${link}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></center></div>`;
}
export function lyricsIt(title: string, lyrics_ls: string[]) {
  let lyrics_str = `<div class="lyrics-container"><h4>Lyrics: ${title}</h4><div class="lyrics-content">`;
  
  for (let i = 0; i < lyrics_ls.length; i++) {
    lyrics_str += `<p><strong>Verse ${i + 1}:</strong><br>`;
    
    // Ensure each line of lyrics appears separately using `<br>`
    lyrics_str += lyrics_ls[i]
      .split('\n') // Split on newlines
      .map(line => line.trim()) // Trim whitespace
      .filter(line => line.length > 0) // Remove empty lines
      .join('<br>'); // Join with <br> for proper new-line rendering

    lyrics_str += `</p>`;
  }
  
  lyrics_str += '</div></div>';
  return lyrics_str;
}
/**
 * Finds media metadata by `src` filename.
 */
export function getMediaData(src: string, media: any[]) {
  const filename = get_filename(src);
  return media.find(
    (item) =>
      item.basename === filename ||
      item.image_name === filename ||
      get_filename(item.og_url || "") === filename
  );
}
async function asyncReplace(
  str: string,
  regex: RegExp,
  callback: (...args: any[]) => Promise<string>
): Promise<string> {
  // Collect all matches
  const matches: { match: string; index: number; groups: string[] }[] = [];
  let match: RegExpExecArray | null;

  // Reset the regex's lastIndex to ensure it starts from the beginning
  regex.lastIndex = 0;

  while ((match = regex.exec(str)) !== null) {
    matches.push({
      match: match[0],
      index: match.index,
      groups: match.slice(1),
    });
  }

  // Process each match asynchronously
  let result = str;
  let offset = 0; // Track the offset due to replacements changing the string length

  for (const { match, index, groups } of matches) {
    try {
      // Await the callback to get the replacement string
      const replacement = await callback(...groups, match, index, str);
      // Calculate the new position after previous replacements
      const start = index + offset;
      const end = start + match.length;
      // Replace the match with the resolved replacement
      result = result.slice(0, start) + replacement + result.slice(end);
      // Update the offset based on the difference in length
      offset += replacement.length - match.length;
    } catch (error) {
      console.error(`Error processing async replacement at index ${index}: ${error}`);
      // Skip the replacement and continue with the next match
    }
  }

  return result;
}
export async function build_content(content: any, meta: any, filename?: string | null): Promise<string> {
  try {
    // Handle synchronous replacements (iframeIt, lyricsIt, imgIt)
    content = content.replace(
      /iframeIt\(\s*"(.*?)"\s*,\s*"(.*?)"\s*\)/g,
      (_: string, title: string, link: string) => iframeIt(title, link)
    );

    content = content.replace(
      /lyricsIt\('(.+?)',\[\s*([\s\S]*?)\s*\]\)/g,
      (_: string, title: string, lyricsRaw: string) => {
        const lyricsArray = lyricsRaw
          .split(/,(?=\s*`)/)
          .map((line) => line.trim().replace(/^`|`$/g, ""));
        return lyricsIt(title, lyricsArray);
      }
    );

    content = content.replace(
      /imgIt\(\s*"(.*?)"\s*,\s*"(.*?)"\s*,?\s*"?(\d+)?"?,?\s*"?(\d+)?"?\)/g,
      (_: string, filename: string, jsonPath: string, width: string | undefined, height: string | undefined) => {
        const media = meta.media;
        const mediaData = getMediaData(filename, media);

        if (mediaData) {
          const imgWidth = width ? parseInt(width, 10) : "auto";
          const imgHeight = height ? parseInt(height, 10) : "auto";

          return `<figure class="seo-image">
  <a href="${mediaData.original || mediaData.url || mediaData.og_url}" target="_blank" rel="noopener noreferrer">
    <img 
      src="${mediaData.url}"
      width="${imgWidth}"
      height="${imgHeight}"
      alt="${mediaData.alt || mediaData.caption || mediaData.basename}"
      title="${mediaData.title || meta.alt || mediaData.basename}"
      loading="lazy"
      style="max-width: 100%; height: auto;"
    />
  </a>
  ${mediaData.caption ? `<figcaption>${mediaData.caption}</figcaption>` : ""}
</figure>`;
        }

        return `<img src="${filename}" alt="Image not found" width="800" height="600" loading="lazy" />`;
      }
    );

    // Handle SeoImgIt replacements asynchronously using asyncReplace
    const seoImgItRegex = /SeoImgIt\(\s*"(.*?)"\s*(?:,\s*"(.*?)"\s*(?:,\s*"?(\d+)?"?\s*(?:,\s*"?(\d+)?"?)?)?)?\)/g;
    content = await asyncReplace(content, seoImgItRegex, async (directory: string, width?: string, height?: string, background?: string) => {
      try {
        const htmlComponent = await fetchImageComponent(
          directory,
          width || '100%',
          height || 'auto',
          background || 'white',
          meta
        );
        return htmlComponent;
      } catch (error) {
        console.error(`Error processing SeoImgIt for ${directory}: ${error}`);
        return `
          <h3 style="width: ${width || '800px'}; height: ${height || '600px'};">
            <figure>
              <img 
                src="/imgs/error.png" 
                alt="Error loading image" 
                title="Error loading image" 
                width="800" 
                height="600" 
                style="background-color: ${background || 'white'}; max-width: 100%; height: auto;" 
                loading="lazy"
              >
              <figcaption>Error loading image: ${error}</figcaption>
            </figure>
          </h3>
        `;
      }
    });

    return content;
  } catch (error) {
    console.error(`Error in build_content for ${filename}: ${error}`);
    return `<p>Error processing content: ${error}</p>`;
  }
}

export const public_dir = path.join(process.cwd(), 'public')
export const src_dir = path.join(process.cwd(), 'src')
export const contents_dir = path.join(src_dir, 'contents')
export const json_pages_dir = path.join(src_dir,'json_pages')
const thedailydialectics_url = 'https://thedailydialectics.com';
const pagesDir = '/var/www/thedailydialectics/src/pages/'
const thedailydialectics_api_url = make_path(thedailydialectics_url, 'api');
const thedailydialectics_metadata_url = make_path(thedailydialectics_api_url, 'meta_info');
const thedailydialectics_directory_lists_url = make_path(thedailydialectics_api_url, 'directory_lists');
const thedailydialectics_navbar_props_url = make_path(thedailydialectics_api_url, 'navbar_props');
const thedailydialectics_directory_path_url = make_path(thedailydialectics_api_url, 'directory_path');
const thedailydialectics_directory_link_url = make_path(thedailydialectics_api_url, 'directory_link');
const thedailydialectics_directory_count_url = make_path(thedailydialectics_api_url, 'directory_count');
const thedailydialectics_source_data_url = make_path(thedailydialectics_api_url, 'source_data');
const thedailydialectics_valid_directory_links = make_path(thedailydialectics_api_url, 'valid_directory_links');
const thedailydialectics_valid_directory_paths = make_path(thedailydialectics_api_url, 'valid_directory_paths');
const thedailydialectics_media_url = make_path(thedailydialectics_api_url, 'get-media')
const thedailydialectics_image_url = make_path(thedailydialectics_api_url, 'get-image-component')
export function formatKeywords(keywords: string | string[] | undefined | null): string {
  if (!keywords) return '';
  
  const keywordList = Array.isArray(keywords) ? keywords : keywords.split(', ');
  return keywordList.join(', ');
}
export async function readBodyContent(content: string): Promise<any> {
  return new Promise<string>((resolve) => {
    setTimeout(() => resolve(content), 500);
  });
}
export async function fetchNavbarProps(): Promise<any> {
  const url = thedailydialectics_navbar_props_url;
  const response = await fetchIt(url);
  console.log('navbar_props response:', response);
  return get_result(response);
}

export async function fetchDirectoryList(directory: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', directory);
  const url = `${thedailydialectics_directory_lists_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  console.log('directory_lists response:', response);
  return response;
}

export async function fetchDirectoryPath(directory: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', directory);
  const url = `${thedailydialectics_directory_path_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}

export async function fetchDirectoryLink(directory: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', directory);
  const url = `${thedailydialectics_directory_link_url}?directory=${encodeURIComponent(directory || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}
export async function fetchDirectoryLinks(directory: string | null = null,string: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', directory);
  const url = `${thedailydialectics_valid_directory_links}?directory=${encodeURIComponent(directory || '')}&string=${encodeURIComponent(string || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}
export async function fetchDirectoryPaths(directory: string | null = null,string: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', directory);
  const url = `${thedailydialectics_valid_directory_paths}?directory=${encodeURIComponent(directory || '')}&string=${encodeURIComponent(string || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}

export async function fetchDirectoryCount(format:string,contentDir: string | null = null): Promise<any> {
  console.log('Fetching api for thedailydialectics directory:', contentDir);
  const url = `${thedailydialectics_directory_count_url}?format=${encodeURIComponent(format || '')}&contentDir=${encodeURIComponent(contentDir || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}

export async function fetchMediaData(): Promise<any> {
  const url = `${thedailydialectics_media_url}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}

export async function fetchMetaData(data:any=null,partial_path:any=null,path:any=null): Promise<any> {
  const url = `${thedailydialectics_metadata_url}`;
  const body = {data,partial_path,path}
  const response = await fetchIt(url, body, null,null, 'POST');
  console.log('directory_path response:', response);
  return get_result(response);
}


export async function fetchImageComponent(directory:string,width:any=null,height:any=null,background:any=null,meta:any=null): Promise<any> {
  const url = `${thedailydialectics_image_url}?directory=${encodeURIComponent(directory || '')}&width=${encodeURIComponent(width || '')}&height=${encodeURIComponent(height || '')}&background=${encodeURIComponent(background || '')}&meta=${encodeURIComponent(meta || '')}`;
  const response = await fetchIt(url);
  console.log('directory_path response:', response);
  return get_result(response);
}

export async function renderSources(sources: Source[]): Promise<any> {
  console.log('Fetching metadata for sources:', sources);
  const url = `${thedailydialectics_source_data_url}`; // Adjust URL as needed
  const response = await fetchIt(url, { sources }, null, null,'POST'); // Use POST with body
  console.log('Metadata response:', response);
  return get_result(response); // Assume this returns Source[]
}

